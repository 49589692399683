.modal-user-edit-container {
    width: 100%;
    height: 90%;
    padding: 20px 10px;
    margin-bottom: 20px;
    display: flex;
}

.modal-user-edit-container .each-detail {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}

.modal-user-edit-container .each-detail .title {
    width: 120px;
    text-align: left;
}
