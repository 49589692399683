/* Facility Management Section */
.facility-container {
  width: 100%;
  height: 100%;
  padding: 50px
}

.facility-container .content {
  display: flex;
  width: 100%;
  height: 100%;
}

.facility-container .content .left {
  width: 20%;
}

.facility-container .content .right {
  width: 100%;
  height: 100%;
}

.facility-container .content .right .header {
  color: white;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 50px;
  line-height: 32px;
}

.facility-container .content .right .table .status-cell {
  display: flex;
  align-items: center;
}

.facility-container .content .right .table .dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 4px;
}

.facility-container .content .right .table .icon {
  cursor: pointer;
}


.facility-container .search-title {
  font-size: 14px;
  color: var(--gray-00);
}

.facility-container .search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  height: 40px;
  width: 100%;
}

.facility-container .search-container .left {
  height: 100%;
  width: 100%;
  display: flex;
}

.facility-container .search-container .left .select {
  font-size: 14px;
  color: white;
  border-radius: 5px;
  background-color: var(--gray-04);
  width: 120px;
  height: 100%;
  margin-right: 10px;
  border: none;
  padding: 0 10px;
}

.facility-container .search-container .select::placeholder {
  color: var(--gray-00);
  font-size: 14px;
}

.facility-container .search-container .search {
  padding: 0 10px;
  border-radius: 5px;
  color: white;
  background-color: var(--gray-04);
  width: 300px;
  height: 100%;
}

.facility-container .search-container .search::placeholder {
  color: #8088a0;
}

.facility-container .search-container .btn-regi {
  background-color: gray;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  color: #496ded;
  font-size: 16px;
  font-weight: bold;
}

.facility-container .btn-del {
  width: 110px;
  height: 40px;
  border: 1px solid var(--gray-00);
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--main-ny-black);
}

.cctv-display-none {
  display: none !important;
}
