/* gis css */
.gis-container {
  height: calc(100vh - 75px);
  display: flex;
  padding: 0 15px 0 15px;
  transition-duration: 0.5s;
}
.gis-container.open {
  width: calc(100%);
}
.gis-container.close {
  width: calc(100% + 290px);
}
.map-container {
  width: 100%;
  height: 100%;
  padding-left: 15px;
}
.map-container #map {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  outline: none;
}

.cursor {
  cursor: pointer;
}
.cursor:hover {
  transform: scale(1.1);
  transition-duration: 0.2s;
}


/* gis/EventListcomponent.js */
.event-panel-container {
  /*height: calc(100vh - 280px);*/
  height: calc(100vh - 125px);
  background-color: var(--main-ny-black);
  color: white;
  padding: 0 28px 15px 15px;
}
/*.event-panel-container {*/
/*  height: 100%;*/
/*}*/
.event-panel-container .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.event-panel-container .header .title {
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
}
.event-panel-container .event-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.event-panel-container .item-list,
.event-write-container {
  height: 100%;
  width: 350px;
  padding: 10px 0;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.event-panel-container .Mui-checked+.MuiSwitch-track {
  background-color: var(--main-ny-blue) !important;
  opacity: 1 !important;
}
.event-panel-container .MuiSwitch-track {
  background-color: gray !important;
}
.event-panel-container .item-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.event-panel-container .item-list .item{
  /*width: 100%;*/
  width: calc(100% - 10px);
  margin: auto;
  background: var(--graa-basic);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 13px 18px;
  position: relative;
  cursor: pointer;
}
.event-panel-container .item-list .item:not(:last-child){
  margin-bottom: 12px;
}
.event-panel-container .item-list .item.red {
  border: 1px solid #FF0000;
  background: rgba(255, 0, 0, 0.2);
}
.event-panel-container .item-list .item .basic {
  display: flex;
  align-items: center;
}
.event-panel-container .item-list .item .basic .img {
  width: 42px;
  height: 42px;
  border-radius: 50px;
  background-color: #FFFFFF;
  margin-right: 10px;
}
.event-panel-container .item-list .item .basic .place > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event-panel-container .item-list .item .basic .place-name {
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 65px);
}
.event-panel-container .item-list .item.red .basic .place-location {
  font-weight: bold;
  color: #FFFFFF;
}
.event-panel-container .item-list .item .basic .place-location {
  font-size: 12px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: var(--gray-01);
}
.event-panel-container .item-list .item .basic .place-location .address{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  width: 90%;
}
.event-panel-container .item-list .item .basic .status {
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.event-panel-container .item-list .item .basic .status > img{
  width: 10px;
  height: 10px;
  /*margin-right: 5px;*/
}
.event-panel-container .item-list .item button{
  width: calc(100% - 52px);
  border-radius: 5px;
  padding: 7px 0;
  font-weight: 500;
}

.event-panel-container .figure-container{
  width: 100%;
  /*height: calc(45% - 35px);*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.event-panel-container .figure-container .item,
.temp123123 div {
  width: 103px;
  background-color: var(--main-ny-sub-black);
  color: #03A8A8;
  font-weight: 700;
  font-size: 11px;
  padding: 18px 0;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 16px;
  overflow: hidden;
  height: 135px;
}
.temp123123 {
  width: 100%;
  margin-top: 30px;
  padding: 0 28px 15px 15px;
}


  /* EventWriteComponent.js */
.event-panel-container .event-write-container {
  height: calc(100% - 30px);
  background-color: var(--main-ny-sub-black);
  border-radius: 5px;
  border: 1px solid #434B61;
  padding: 21px 25px 25px 25px;
  display: flex;
  flex-direction: column;
}
.event-panel-container .event-write-container .event-write-title {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 23px;
}
.event-panel-container .event-write-container .event-write-time {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-00);
  margin-bottom: 12px;
}
.event-panel-container .event-write-container .event-write-input {
  background-color: #3F4452;
  border-radius: 5px;
  /*color: var(--gray-00);*/
  width: 100%;
  font-size: 16px;
  padding: 8px 14px;
  margin-bottom: 10px;
  outline: none;
  border: none;
  color: #FFFFFF;
}
.event-panel-container .event-write-container .event-write-input:first-child {
  margin-bottom: 0;
}
.event-panel-container .event-write-container select.event-write-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, var(--gray-00) 50%), linear-gradient(135deg, var(--gray-00) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em - 1px), calc(100% - 15px) calc(1em - 1px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
.event-panel-container .event-write-container textarea.event-write-input {
  width: calc(100% - 29px);
  resize: none;
  height: 100%;
}
.event-panel-container .event-write-container textarea.event-write-input::-webkit-input-placeholder,
.event-panel-container .event-write-container input.event-write-input::-webkit-input-placeholder {
  color: var(--gray-00);
  font-size: 16px;
}
.event-panel-container .event-write-container textarea.event-write-input:-ms-input-placeholder,
.event-panel-container .event-write-container input.event-write-input:-ms-input-placeholder {
  color: var(--gray-00);
  font-size: 16px;
}
.event-panel-container .event-write-container .event-write-photo {
  max-height: 190px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 21px;
  overflow-y: auto;
}
.event-panel-container .event-write-container .event-write-photo .event-write-photo-empty {
  color: var(--gray-00);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.event-panel-container .event-write-container .event-write-photo-item {
  width: 100%;
  background-color: #3F4452;
  border-radius: 5px;
  padding: 10px 13px;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.event-panel-container .event-write-container .event-write-photo-item:last-child {
  margin-bottom: 0;
}
.event-panel-container .event-write-container button {
  padding: 10px 0 12px 0;
  background-color: #515966;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: 700;
}
.event-panel-container .event-write-container button.submit {
  background-color: var(--main-ny-red);
  margin-bottom: 6px;
}
.event-panel-container .event-write-container .event-write-input button{
  background-color: transparent;
  height: 33px;
}
.event-panel-container .event-write-container .event-write-input button:last-child{
  margin-right: 10px;
}
.event-panel-container .event-write-container .event-write-input input:focus{
  border: none;
}


/* MapTools.js */
.map-tools.center {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  display: flex;
  transition-duration: 0.5s;
}
.map-tools.right{
  position: absolute;
  z-index: 2;
  right: 22px;
  top: 20px;
  font-size: 10px;
  display: flex;
}
.map-tools > div {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
.map-tools.right .map-option {
  position: absolute;
  bottom: -85px;
  width: 220px;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.map-tools.right .map-option.type {
  left: 0;
}
.map-tools.right .map-option.info {
  left: 60px;
}
.map-tools.right .map-option img {
  border-radius: 100px;
  padding: 7px;
}
.map-tools.right .map-option > div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.map-tools.right .map-option.open {
  display: flex;
}
.map-tools.right .map-option.close {
  display: none;
}
.map-tools > div{
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
  margin-right: 10px;
  cursor: pointer;
}
.map-tools.right .sub-tool {
  position: absolute;
  top: 60px;
  left: -100px;
  width: calc(100% + 100px);
  height: auto;
  display: block;
  padding: 10px;
}
.map-tools.right .sub-tool .title {
  text-align: left;
  margin: 10px 20px;
  font-weight: bold;
  font-size: 14px;
}
.map-tools.right .sub-tool .layer-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 10px;
}
.map-tools.right .sub-tool .layer-list .layer-list-item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
}
.map-tools.right .sub-tool .layer-list .layer-list-item .label-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.map-tools.right .sub-tool .layer-list .layer-list-item .img-container{
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-tools.right .sub-tool .layer-list .layer-list-item img{
  max-width: 40px;
  max-height: 40px;
}
.map-tools.right .sub-tool .layer-list .layer-list-item div{
  text-align: center;
  margin: 7px 0;
  width: 80px;
}

.map-tools .facility-menu {
  position: absolute;
  top: 60px;
  left: 60px;
  display: flex;
  flex-direction: row;
  width: 180px;
  justify-content: space-around;
  padding: 36px 0;
}
.map-tools .facility-menu.none {
  display: none;
}
.map-cctv-infowindow {
  background: #04101B;
  opacity: 0.9;
  /* main_NY_Blck */
  border: 1px solid var(--main-ny-black);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 7px 9px;
  display: flex;
  align-items: center;
}


/* CCTVSideComponent.js */
.cctv-monitoring-bar {
  height: 100%;
  padding-right: 20px;
  color: white;
  transition-duration: 0.5s;
}
.cctv-monitoring-bar.open{
  transform: translateX(0);
}
.cctv-monitoring-bar.close{
  transform: translateX(calc(100% + 20px));
}
.cctv-monitoring-bar .cctv-list {
  width: 100%;
  height: calc(100% - 40px);
  /*padding-bottom: 30px;*/
  overflow-y: scroll;
  margin-bottom: 10px;
  /*overflow-y: auto;*/
}
.cctv-monitoring-bar .MuiPagination-ul *{
  color: white !important;
}
.cctv-monitoring-bar .MuiPagination-ul .Mui-selected {
  background-color: #FFFFFF;
  color: black !important;
}
.cctv-monitoring-bar .MuiPagination-ul {
  justify-content: center;
}


/* AddressInput.js */
.address-input {
  position: relative;
}
.address-input .search {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  padding-left: 15px;
}
.address-input .results div {
  cursor: pointer;
}
.address-input .results div.white-theme:hover{
  background-color: #EEE;
}
.address-input .results div.dark-theme:hover{
  background-color: var(--gray-04);
}


.modal-with-bg {
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: #00000090;
  position: absolute;
  left: 0;
  top: 0;
}

/* CCTVViewModal.js */
.gis-cctv-modal, .raw-modal {
  position: absolute;
  left: 50%;
  top: 20%;
  background-color: #000000;
  border-radius: 5px;
  color: white;
  padding: 20px 17px;
  z-index: 110 !important;
}
.gis-cctv-modal.unset, .raw-modal.unset {
  position: unset !important;
}

.gis-cctv-modal.flexible-modal, .raw-modal.flexible-modal {
  background: #000000 !important;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0;
}
.gis-cctv-modal.flexible-modal.bring-to-front {
  z-index: 200 !important;
}
.gis-cctv-modal .flexible-modal-drag-area, .raw-modal .flexible-modal-drag-area{
  background: transparent;
  border-radius: 5px;
}
.gis-cctv-modal .flexible-modal-resizer, .raw-modal .flexible-modal-resizer{
  /*border-bottom: solid 2px #999999;*/
  /*border-right: solid 2px #999999;*/
  right: 5px;
  bottom: 5px;
}
.gis-cctv-modal .body:focus {
  outline: none;
}
.gis-cctv-modal .body, .raw-modal .body {
  padding: 20px 17px;
  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)";
  border: 0.5px solid #333333;
  border-radius: 5px;
  border-bottom: none;
  height: 100%;
}
.gis-cctv-modal .body .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.gis-cctv-modal .body .header .place {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.gis-cctv-modal .body .header > div {
  display: flex;
  align-items: center;
}
.gis-cctv-modal .body .header > div > div{
  display: flex;
  align-items: center;
  height: auto;
}

.gis-cctv-modal .body .header .place {
  font-size: 20px;
  font-weight: bold;
}

.gis-cctv-modal .body .header .modify {
  font-size: 14px;
  color: #999999;
  text-decoration: underline;
  cursor: pointer;
  z-index: 1;
}
.gis-cctv-modal .body .header .close {
  font-size: 35px;
  margin-left: 12px;
  cursor: pointer;
  z-index: 1;
}
.gis-cctv-modal .body .address {
  color: #999999;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.gis-cctv-modal .body .view-panel{
  display: flex;
  margin: 12px 0;
  justify-content: space-between;
  height: 30%;
}
.gis-cctv-modal .body .view-panel .view {
  width: calc(100% - 120px);
  height: 220px;
  border: 1px solid #000000;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
}
.gis-cctv-modal .body .view-panel .view.full-screen {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 120;
  background-color: #000000;
}
.gis-cctv-modal .body .view-panel .view .live {
  position: absolute;
  display: inline-block;
  background-color: red;
  border-radius: 10px;
  padding: 3px 7px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 7px;
  left: 7px;
  z-index: 1;
}
.gis-cctv-modal .body .view-panel .view .live.full-screen {
  left: 15px;
  top: 15px;
}

.video-btn {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 7px;
  bottom: 7px;
}
.video-btn.full-screen {
  right: 15px;
  bottom: 15px;
}
.video-btn div {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  z-index: 1;
}
.video-btn canvas.video-btn-bg {
  background-color: #00000080;
}

.video-btn.full-screen div{
  width: 50px;
  height: 50px;
}

.video-btn div img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.gis-cctv-modal .body .view-panel .control {
  width: 100px;
  height: 220px;
  display: flex;
  flex-direction: column;
}
.gis-cctv-modal .body .view-panel .control .title{
  font-size: 10px;
  line-height: 12px;
  color: #6A6E76;
  margin-bottom: 4px;
}
.gis-cctv-modal .body .divide {
  width: 100%;
  height: 1px;
  background-color: #6A6E76;
  margin: 13px 0;
}
.gis-cctv-modal .body .view-panel .control .control-btn {
  background-color: #FFFFFF;
  width: 28px;
  height: 28px;
  color: white;
  font-size: 25px;
  padding: 4px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}
.gis-cctv-modal .body .view-panel .control .control-btn.center {
  width: 24px;
  height: 28px;
}
.gis-cctv-modal .body .view-panel .control .control-btn.disabled {
  cursor: default;
}
.gis-cctv-modal .body .view-panel .control .control-btn:nth-child(2) img {transform: rotate(90deg);}
.gis-cctv-modal .body .view-panel .control .control-btn:nth-child(3) img {transform: rotate(90deg);}
.gis-cctv-modal .body .view-panel .control .control-btn:nth-child(6) img {transform: rotate(180deg);}
.gis-cctv-modal .body .view-panel .control .control-btn:nth-child(7) img {transform: rotate(270deg);}
.gis-cctv-modal .body .view-panel .control .control-btn:nth-child(8) img {transform: rotate(270deg);}
.gis-cctv-modal .body .view-panel .control .control-btn:nth-child(9) img {transform: rotate(180deg);}
.gis-cctv-modal .body .view-panel .control .control-btn:active:not(.disabled) {
  background-color: var(--main-ny-blue);
}
.gis-cctv-modal .body .view-panel .control .control-btn:hover:not(.disabled):not(:active) {
  background-color: #CCC;
}

.gis-cctv-modal .body .view-panel .control .zoom-control {
  display: flex;
  flex-direction: row;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
}
.gis-cctv-modal .body .view-panel .control .zoom-control .control-btn {
  background-color: transparent;
  width: 50%;
}
.gis-cctv-modal .body .view-panel .control .direction .direction-control {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gis-cctv-modal .body .place-name {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gis-cctv-modal .body .place-name > div{
  display: flex;
  align-items: center;
}
.gis-cctv-modal .body button {
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #AAAAAA;
  color: #AAAAAA;
  font-weight: bold;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 20px;
  margin: 17px 0 9px 0;
}
.gis-cctv-modal .body button:hover {
  background-color: #FFFFFF20;
}
.gis-cctv-modal .body .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gis-cctv-modal .body .bottom button {
  margin: 0;
  cursor: default;
}
.gis-cctv-modal .body .bottom button:hover {
  /*background-color: ;*/
  background-color: #000000;
}

.gis-cctv-modal .body .create {
  color: white;
  background-color: #496DED;
  border: 1px solid #496DED;
  margin-left: 8px;
  font-weight: 700;
  display: flex;
  align-items: center;

}
.capture-img {
  overflow: auto;
  max-height: 190px;
  /*min-height: 190px;*/
  height: 100%;
  box-sizing: border-box;
  margin: 10px 0;
}
.capture-img > label {
  width: 32%;
  height: 85px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
  margin-bottom: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
}
.capture-img > label:nth-child(3n) {
  margin-right: 0;
}

.capture-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 5px;
}
.capture-img input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 2px;
}
.gis-cctv-modal .body .select-wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #999999;
  justify-content: space-between;
  margin-top: 20px;
}
.gis-cctv-modal .body .select-wrapper > div{
  display: flex;
  align-items: center;
}

/* gis draggable modal */
.gis-cctv-storage.flexible-modal {
  background: #31353E !important;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.gis-cctv-storage .flexible-modal-drag-area{
  background: transparent;
  border-radius: 20px;
}
.gis-cctv-storage .flexible-modal-resizer {
  border-bottom: solid 2px #000000;
  border-right: solid 2px #000000;
  right: 5px;
  bottom: 5px;
}
.gis-cctv-storage .body {
  padding: 23px 25px 25px 30px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.gis-cctv-storage .body:focus {
  outline: none;
}
.gis-cctv-storage .body .header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.gis-cctv-storage .body .header .title,
.gis-cctv-storage .body .header .times {
  font-weight: 700;
  font-size: 18px;
  color: #FFFFFF;
}
.gis-cctv-storage .body .header .times {
  font-size: 30px;
  z-index: 1;
  cursor: pointer;
}
.gis-cctv-storage .body .content {
  margin-bottom: 10px;
}
.gis-cctv-storage .body .content .select-all{
  font-size: 14px;
  color: #999999;
  display: flex;
  align-content: center;
  margin-bottom: 10px;
}
.gis-cctv-storage .body .content .image-container {
  height: 100%;
  overflow: auto;
}

.gis-cctv-storage .body .content .image-container .image-wrapper {
  display: inline-flex;
  margin-right: 8px;
  margin-bottom: 18px;
  width: 127px;
  position: relative;
  cursor: pointer;
}
.gis-cctv-storage .body .content .image-container .image-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: 1px solid #656565;
}
.gis-cctv-storage .body .content .image-container .image-wrapper input[type="checkbox"] {
  position: absolute;
  left: 3px;
  top: 3px;
}
.cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(73, 117, 237, 0.2), rgba(73, 117, 237, 0.2));
  border: 1px solid #496DED;
  border-radius: 3px;
}
.gis-cctv-storage .body .content .image-container .image-wrapper .cover input {
  margin-left: 8px;
  margin-top: 8px;
}
.gis-cctv-storage .body .bottom {
  display: flex;
  width: 100%;
  padding-top: 20px;
}
.gis-cctv-storage .body .bottom div {
  cursor: pointer;
  padding: 13px 0;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  border-radius: 5px;
  text-align: center;
}
.gis-cctv-storage .body .bottom .delete-btn {
  background-color: #515966;
  width: 30%;
  margin-right: 7px;
}
.gis-cctv-storage .body .bottom .attach-btn {
  background-color: var(--main-ny-blue);
  width: 70%;
}
.gis-cctv-storage .body .bottom .attach-btn img {
  margin-right: 13px;
}


/* raw modal */
.raw-modal {
  color: #333333;
  outline: none;
}
.raw-modal.flexible-modal {
  border-radius: 20px;
}
.raw-modal .flexible-modal-drag-area {
  height: 35px;
  /*background-color: #66339990 !important;*/
}
.raw-modal .body .close {
  font-size: 20px;
  text-align: right;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 15px;
  top: 15px;
}
.raw-modal .body .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-bottom: 10px;
  padding: 20px 10px 10px 10px;
  border-radius: 20px;
}

.raw-modal .body{
  background-color: #FFFFFF;
  padding: 15px;
  outline: none;
  border-radius: 20px;
  height: 100%;
}
.raw-modal .body .table-container {
  height: calc(100% - 70px);
  overflow-y: auto;
}
.raw-modal .body .table-container table thead{
  font-weight: bold;
  text-align: center;
  overflow-y: auto;
}
.raw-modal .body .table-container table tr td{
  border: 1px solid #333;
  padding: 3px;
  word-break: break-word;
}
.raw-modal .body .table-container table tr td.ta-center {
  text-align: center;
}
.raw-modal .body .table-container table tr td.separate-field {
  background-color: #DDD;
  text-align: left;
  padding-left: 10px;
}

/* messageBox */
.messageBox.open {
  animation-name: open;
  animation-duration: 1s;
}
.messageBox.close {
  animation-name: close;
  animation-duration: 1s;
}

@keyframes open {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes close {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}


/* gis - event occur marker animation */
.event-occur-marker {
  position: relative;
  display: block;
  width: 36px;
}
.event-occur-marker img {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
}
.event-occur-marker .circle {
  display: block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: #FF0000;
  opacity: 0.5;
  transition: 5s ease;
  position: absolute;
  left: 50%;
  top: 50%;
}
.event-occur-marker .wave1 {
  animation: waves 2.5s linear infinite;
  animation-delay: .1s;
}
.event-occur-marker .wave2 {
  animation: waves 2.5s linear .7s infinite;
}
.event-occur-marker .wave3 {
  animation: waves 2.5s linear 1.3s infinite;
}

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}

.cctv-canvas-display-block {
  display: block !important;
}

.cctv-canvas-display-none {
  display: none !important;
}
