.signin-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signin-wrapper {
  display: flex;
}

.native-signin {
  display: flex;
  flex-direction: column;
  height: 500px;
  /* width: 427px;
    margin-right: 100px; */
}

.native-signin .header,
.foreign-signin .header {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 2rem;
}

.foreign-signin .social-btn-group {
  display: flex;
  flex-direction: column;
}

.native-signin hr {
  margin: 1.5rem 0;
}

.signin-input {
  /*border: 1px solid black;*/
  /*border-radius: 0px;*/
  margin-top: 1rem !important;
  /*height: 62px;*/
  /*padding: 1px 15px;*/
}

.save-credentials {
  margin-top: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
}

.auth-error {
  color: red;
  font-size: 10px;
}

.save-credentials input[type="checkbox"] {
  cursor: pointer;
  position: relative;
  transition: 0.2s;
}

.save-credentials input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  background-color: white;
  transition: 0.2s;
}

.save-credentials input[type="checkbox"]::after {
  content: "✓";
  position: absolute;
  top: 0;
  left: 3px;
  width: 15px;
  height: 15px;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  color: black;
  opacity: 0;
  transition: 0.2s;
}

.save-credentials input[type="checkbox"]:checked::after {
  opacity: 1;
}

.save-credentials input[type="checkbox"]:hover::after {
  opacity: 0.4;
}

.save-credentials label {
  padding-right: 1.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
}

.credentials-restore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
}

.credentials-restore button a {
  color: #6d6d6d !important;
}

.signin-btn {
  border: 1px solid black !important;
  border-radius: 30px !important;
  height: 62px;
}

.signin-btn:hover {
  background-color: black !important;
  color: white;
}

.foreign-signin {
  display: flex;
  flex-direction: column;
  height: 500px;
  /* width: 427px; */
}

.alert {
  align-items: center;
}
.alert button {
  margin-left: 1rem;
}
.alert button svg {
  color: white;
  border: 1px solid white;
  border-radius: 3px;
}

@media screen and (max-width: 576px) {
  .native-signin {
    height: auto;
  }
  .foreign-signin {
    height: auto;
    padding-bottom: 50px;
  }
  .native-signin .header {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 1rem;
    margin-top: 10rem;
    margin-left: auto;
    margin-right: auto;
  }
  .foreign-signin .header {
    font-size: 1.5em;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  .native-signin hr {
    margin: 1rem 0;
  }

  .foreign-signin .social-btn-group {
    display: flex;
    flex-direction: row;
  }

  .save-credentials label span {
    font-size: 0.8rem;
  }
  .credentials-restore {
    padding: 0;
  }

  .signin-btn {
    margin-top: 1rem !important;
    height: 42px;
  }
}
