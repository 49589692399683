.detail_list {
    width: 60%;
    overflow: auto;
    height: 300px;
    overflow-x: hidden;
}

.item_container {
    display: flex;
    align-items: center;
    padding: 10px 6px;
}

.title {
    min-width: 110px;
}

.detail {
    width: 360px;
    word-break: break-word;
    max-height: 100px;
    overflow: auto;
    min-height: 20px;
}
