.event-detail-title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}

.event-detail-title-container .left {
    display: flex;
    align-items: center;
}

.event-detail-title-container .left > h2 {
    font-size: 22px;
    font-weight: bold;
}

.event-detail-title-container .left .full-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    cursor: pointer;
    background-color: var(--main-ny-sub-black);
    color: white;
    border-radius: 5px;
    height: 27px;
    margin-left: 13px;
    font-size: 12px;
    font-weight: bold;
}

.event-detail-title-container .right {
    display: flex;
    align-items: center;
}

.event-detail-title-container .btn-close {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.event-detail-content-container .top {
    padding: 10px 30px;
    border-radius: 10px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.event-detail-content-container .top .left {
    display: flex;
    align-items: center;
}

.event-detail-content-container .top .left .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;

}

.event-detail-content-container .top .right .btn {
    width: 116px;
    height: 45px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
}

.event-detail-content-container .top .right .btn-handle {
    background-color: white;
    color: var(--main-ny-sub-black);
    border: 1px solid var(--main-ny-sub-black);
}

.event-detail-content-container .top .right .btn-quite {
    background-color: var(--main-ny-sub-black);
    color: white;
    margin-left: 10px;
}

.event-detail-content-container .bottom {
    display: flex;
}

/*.event-detail-content-container .bottom .left {*/
/*    width: 60%;*/
/*    overflow: auto;*/
/*    height: 300px;*/
/*    overflow-x: hidden;*/
/*    !*scrollbar-width: none;*!*/
/*    !*-ms-overflow-style: none;*!*/
/*}*/

/*.event-detail-content-container .bottom .left .each-row {*/
/*    display: flex;*/
/*    padding: 0 16px;*/
/*    font-size: 16px;*/
/*}*/

/*.event-detail-content-container .bottom .left .each-row .title {*/
/*    width: 100px;*/
/*}*/

/*.event-detail-content-container .bottom .left .each-row .detail {*/
/*    width: 300px;*/
/*}*/

.event-detail-content-container .bottom .right {
    width: 40%;
}

.event-detail-content-container .bottom .right .content-container {
    display: flex;
    width: 100%;
    height: 260px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 65.27%);
}

.event-detail-content-container .bottom .right .content-container .line {
    width: 1px;
    background-color: #DDDDDD;
    margin-right: 24px;
}

.event-detail-content-container .input-container {
    display: flex;
    margin-top: 30px;
}


