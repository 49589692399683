.nav-container {
  display: flex;
  flex-direction: row;
  color: black;
  width: 100%;
  height: 100vh;
  background-color: var(--main-ny-black);
  /*overflow: hidden;*/
  /* z-index: 19; */
}

.side-container {
  padding: 15px 18px;
  height: 100vh;
}

.menu-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: center;
  width: 72px;
  border-radius: 16px;
  background-color: var(--main-ny-sub-black);
  padding-top: 180px;
  padding-bottom: 20px;
  z-index: 10;
  height: 100%;
  justify-content: space-between;
}

.menu-container .menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.menu-container .btn-container {
  justify-content: center;
  cursor: pointer;
}

.menu-container .btn-container .btn-logout {
  color: white;
  font-size: 25px;
  cursor: pointer;
}

.nav-container .content {
  /*position: relative;*/
  /*width: calc(100% - 110px);*/
  height: calc(100% - 40px);
  overflow: hidden;
  box-sizing: border-box;
}

.link {
  /* flex-direction: column; */
  width: 100%;
  padding: 10px 0;
}

.btn-container .icon:hover,
.link:hover:not(.link.active) {
  transform: scale(1.2);
  transition-duration: 0.2s;
}


/* notification css */
.notification {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  height: auto !important;
  box-shadow: none !important;
}
.notification .notification-dismiss {
  right: 10px !important;
  top: 10px !important;
  display: none;
}

.notifications-wrapper.withBg{
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: #33333390;
  z-index: 150000;
}
