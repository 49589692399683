:root {
  --main-ny-black: #04101B;
  --main-ny-sub-black: #242834;
  --main-ny-red: #FF0000;
  --main-ny-blue: #496DED;

  --gray-00: #8088A0;
  --gray-01: #A2ABC3;
  --gray-02: #AAAAAA;
  --gray-03: #E5E5E5;
  --gray-04: #313644;
  --gray-line: #DDDDDD;

  --gradi: linear-gradient(90deg, #31353E 0%, #1A202F 100%);
  --graa: linear-gradient(90.18deg, #43242B 0%, rgba(49, 54, 68, 0.42) 90.38%);
  --graa2: linear-gradient(90.43deg, #1E4446 0%, rgba(49, 54, 68, 0.5) 65.11%);
  --graa-basic: linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  overflow: hidden;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flexible-modal {
  z-index: 105 !important;
  position: absolute;
  border: none !important;
  background: transparent !important;
}
.flexible-modal-mask, .flexible-modal-drag-area-right, .flexible-modal-drag-area-left {
  display: none;
  z-index: 105;
}
.flexible-modal-mask.withBackground {
  display: block;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  background: transparent !important;
  height: 50px;
  position:absolute;
  right: 0;
  top: 0;
  cursor:move;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "NanumGothic";
  src: url('./font/NanumGothic.eot'); /* IE9 Compat Modes */
  src: url('./font/NanumGothic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./font/NanumGothic.woff2') format('font-woff2'), /* Super Modern Browsers */
  url('./font/NanumGothic.woff') format('woff'), /* Pretty Modern Browsers */
  url('./font/NanumGothic.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('./font/NanumGothic.svg#NanumGothic') format('svg'); /* Legacy iOS */
}
