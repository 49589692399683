.setting-modal {
  position: absolute;
  z-index: 200;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.setting-modal-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid #DDDDDD;
}
/* modal-header */
.setting-modal-container .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  border-bottom: 1px solid #DDDDDD;
}
.setting-modal-container .header div{
  display: inline-block;
  display: flex;
  align-items: center;
}
.setting-modal-container .header .title{
  font-weight: 700;
  letter-spacing: -0.03em;
  color: #333333;
}
.setting-modal-container .header .close{
  font-size: 30px;
  font-weight: 400;
  z-index: 1;
  cursor: pointer;
  margin: 22px 0;
}


/* SettingModal */
/* modal-content left*/
.setting-modal-container .content2 {
  display: flex;
  flex-direction: row;
  height: calc(100% - 56px);
}
.setting-modal-container .content2 .left {
  width: 300px;
  border-radius: 0 0 0 10px;
}
.setting-modal-container .content2 .left .profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 25px 17px 25px;
  border-bottom: 1px solid #DDDDDD;
}
.setting-modal-container .content2 .left .profile-img {
  width: 46px;
  height: 46px;
  margin-right: 10px;
}
.setting-modal-container .content2 .left .department {
  font-size: 12px;
  margin-top: 5px;
}
.setting-modal-container .content2 .left .setting-list .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 21px;
}
.setting-modal-container .content2 .left .setting-list .item img{
  margin-right: 8px;
}

/* modal-content right*/
.setting-modal-container .content2 .right {
  width: calc(100% - 300px);
  height: 100%;
  background-color: #F8F8F9;
  border-radius: 0 0 10px 0;
  padding: 26px 30px;
  position: absolute;
  right: 0;
  top: 0;
}
.setting-modal-container .content2 .right.setting {
  padding: 16px 30px 26px 30px;
}
.setting-modal-container .content2 .right.list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.setting-modal-container .content2 .txt-btn {
  color: #808080;
  cursor: pointer;
}
.setting-modal-container .content2 .txt-btn.active {
  font-weight: bold;
  color: #333333;
}
.setting-modal-container .content2 .inactive {
  color: #808080;
  cursor: default !important;
}
.setting-modal-container .content2 .txt-btn.active,
.setting-modal-container .content2 .txt-btn:hover {
  font-weight: bold;
  color: #333333;
}
.setting-modal-container .content2 .red-txt-btn {
  line-height: 20px;
  text-align: center;
  width: 25px;
  height: 20px;
  color: #FFFFFF;
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.9);
  border-radius: 200px;
  cursor: pointer;
}
.setting-modal-container .content2 .right.list .service-list,
.setting-modal-container .content2 .right.list .facility-list {
  margin: 20px 2px 0 2px;
  /*overflow-y: auto;*/
  height: 100%;
}
.setting-modal-container .content2 .right.list .service-list .item,
.setting-modal-container .content2 .right.list .facility-list .item {
  padding: 8px 18px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 2px;
}
.setting-modal-container .content2 .right.list .service-list .item:first-child {
  margin-top: 3px;
}
.setting-modal-container .content2 .right.list .service-list .item:last-child {
  margin-bottom: 3px;
}
.setting-modal-container .content2 .right.list .service-list .item > img{
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.setting-modal-container .content2 .service-list .item .text-container {
  width: 100%;
}
.setting-modal-container .content2 .service-list .item .text-container .small-txt {
  font-size: 14px;
  margin-top: 4px;
}
.setting-modal-container .content2 .right.list .service-list .item .text-container .status-txt {
  font-size: 10px;
  margin-right: 5px;
}
.setting-modal-container .content2 .right.list .service-list .item .text-container .status-txt.red {
  color: #EC1C00;
}
.setting-modal-container .content2 .right.list .service-list .item .text-container .status-txt.green {
  color: #78D700;
}
.setting-modal-container .content2 .right.list .facility-list .item img {
  max-width: 44px;
  max-height: 44px;
}
.setting-modal-container .content2 .right.list .facility-list .item .icon-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
}
.setting-modal-container .content2 .right.list .facility-list .item .icon-container .icon{
  margin-right: 10px;
  border-radius: 100px;
}

.setting-modal-container .content2 .right .btn {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 15px 0;
  width: 100%;
}
.setting-modal-container .content2 .right .btn2 {
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #1B2649;
  padding: 12px 0;
  width: 100%;
  text-align: center;
  font-weight: 700;
}
.setting-modal-container .content2 .right .btn2.close {
  background-color: #1b2649;
  color: #FFFFFF;
}
.setting-modal-container .content2 .right .service-icon-container {
  text-align: center;
  margin: 20px 0 20px 0;
}
.setting-modal-container .content2 .right .service-icon-container img {
  width: 90px;
  height: 90px;
  border-radius: 100px;
}
.setting-modal-container .content2 .right .service-icon-container .no-img {
  width: 90px;
  height: 90px;
  border: 1px dashed #808080;
  margin: auto;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.setting-modal-container .content2 .right .service-icon-container .no-img svg{
  width: 90px;
  height: 90px;
}
.setting-modal-container .content2 .right.setting .item-container {
  margin: 15px 0;
  height: calc(50%);
  overflow-y: auto;
}
.setting-modal-container .content2 .right.setting .item {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 5px 10px 5px 20px;
  margin: 9px 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.setting-modal-container .content2 .right.setting .item:last-child {
  margin-bottom: 2px;
}
.setting-modal-container .content2 .right.setting .item .small-txt {
  font-size: 11px;
  color: var(--gray-01);
  margin-left: 8px;
}
.setting-modal-container .content2 .add-modal {
  padding: 22px 24px;
  height: calc(100% - 56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.setting-modal-container .content2 .add-modal .list-wrapper{
  height: 100%;
  margin-bottom: 22px;
  overflow-y: auto;
}
.setting-modal-container .content2 .add-modal .list-wrapper .item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  margin: 2px 2px 12px 2px;
  padding: 0 14px 22px 14px;
  width: calc(100% - 5px);
}
.setting-modal-container .content2 .add-modal .list-wrapper .item:last-child {
  margin-bottom: 2px;
}
.setting-modal-container .content2 .add-modal .list-wrapper .item .category-list {
  margin-top: 22px;
  width: 100%;
}
.setting-modal-container .content2 .add-modal .list-wrapper .item .category-list .category-item {
  display: flex;
  align-items: center;
  padding: 3px 12px;
}
.setting-modal-container .content2 .add-modal .list-wrapper .item.category-item {
  padding: 18px 18px 18px 23px;
  display: flex;
  justify-content: space-between;
}
.setting-modal-container .content2 .add-modal .list-wrapper .item .category-list .category-item img {
  margin-right: 10px;
}
.setting-modal-container .content2 .add-modal .item.selectedItem {
  background-color: #1B2649;
  color: #FFFFFF;
}
.setting-modal-container .content2 .add-modal .item .service-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}
.setting-modal-container .content2 .add-modal .list-wrapper .item .img-wrapper img{
  width: 44px;
  height: 44px;
  margin-right: 10px;
  margin-top: 8px;
}

.setting-modal-container .content2 .btn-wrapper{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.setting-modal-container .content2 .btn-wrapper .btn2:first-child{
  margin-right: 10px;
  width: 70%;
}


/* profile */
.setting-modal-container .content2 .right.profile {
  justify-content: space-between !important;
  display: flex;
  flex-direction: column;
}
.setting-modal-container .content2 .right.profile .item {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 14px 18px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.setting-modal-container .content2 .right.profile .item .text-container{
  margin-left: 15px;
}
.setting-modal-container .content2 .right.profile .item .text-container .small-txt {
  font-size: 12px;
  margin-top: 4px;
}


/* 비밀번호 변경 모달 */
/* 소유자 계정 이전 모달 */
.setting-modal-container .content2 .change-password-modal,
.setting-modal-container .content2 .transfer-account-modal {
  padding: 12px 24px 22px 24px;
  height: calc(100% - 56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



/* resizable-draggable css */
.setting-modal .flexible-modal-drag-area {
  height: 56px;
}
