.modal-dialog-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-container > h2 {
  font-weight: bold;
  font-size: 25px;
  margin: 20px 0px;
}

.modal-dialog-container .detail {
  margin-bottom: 20px;
  line-height: 25px;
  font-size: 17px;
}

.modal-dialog-container .btn-container {
  display: flex;
  justify-content: space-evenly;
}

.modal-dialog-container .btn-container > button {
  width: 45%;
  height: 45px;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.modal-dialog-container .btn-container .btn-cancel {
  background-color: white;
  border: 1px solid var(--main-ny-sub-black);
  color: var(--main-ny-sub-black);
}

.modal-dialog-container .btn-container .btn-ok {
  background-color: var(--main-ny-sub-black);
  color: white;
}
