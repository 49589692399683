body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif; */
    font-family: "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
    width: 100%;
    box-sizing: border-box;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    font-family: "NanumGothic";
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration-line: none;
    color: black;
}

input {
    border: none;
    box-sizing: border-box;
}

input:focus {
    outline-width: 0;
}

textarea:focus {
    outline-width: 0;
}

button {
    border: none;
    cursor: pointer;
    /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
}

.hide-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.hide-scroll::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

.a::-webkit-scrollbar {
    background-color: tomato;
    border-radius: 5px;
    width: 1px;
}

.a::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 5px;
}

.a::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 5px;
}

.scroll-b::-webkit-scrollbar {
    background-color: tomato;
    border-radius: 5px;
    width: 8px;
}

.scroll-b::-webkit-scrollbar-thumb {
    background-color: var(--gray-line);
    border-radius: 5px;
}

.scroll-b::-webkit-scrollbar-track {
    background-color: var(--main-ny-sub-black);
    border-radius: 5px;
}

.scroll-c::-webkit-scrollbar {
    background-color: tomato;
    border-radius: 5px;
    width: 3px;
    height: 8px;
}

.scroll-c::-webkit-scrollbar-thumb {
    background-color: #A8A8A8;
    border-radius: 5px;
}

.scroll-c::-webkit-scrollbar-track {
    background-color: var(--main-ny-sub-black);
    border-radius: 5px;
}


.pages > ul > li > button {
    background-color: #71788e;
    color: white;
}

.pages > ul > li > .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #4f5b8a;
}

.MuiTable-root {
    border-spacing: 0 10px !important;
    border-radius: 5px !important;
}

.MuiTableCell-root {
    border-bottom: none !important;
}

.MuiTableRow-root {
    border-radius: 5px !important;
}

.MuiTableCell-head {
    padding: 0 16px !important;
}

/* React-Select Container Style */
.css-b62m3t-container {
    width: 100%
}

.hover-opacity:hover {
    opacity: 0.5;
}

.hover-opacity-5:hover {
    opacity: 0.05;
}

.hover-opacity-10:hover {
    opacity: 0.1;
}

.hover-opacity-20:hover {
    opacity: 0.2;
}

.hover-opacity-30:hover {
    opacity: 0.3;
}

.hover-opacity-40:hover {
    opacity: 0.4;
}

.hover-opacity-50:hover {
    opacity: 0.5;
}

.hover-shadow:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.hover-transform:hover {
    transform: scale(1.05);
    transition-duration: 250ms;
}

.hover-transform-1:hover {
    transform: scale(1.01);
    transition-duration: 250ms;
}

.hover-transform-3:hover {
    transform: scale(1.03);
    transition-duration: 250ms;
}

.hover-transform-5:hover {
    transform: scale(1.05);
    transition-duration: 250ms;
}

.hover-transform-10:hover {
    transform: scale(1.10);
    transition-duration: 250ms;
}
