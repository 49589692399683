.common-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
  padding: 0px 30px;
}

.common-header-container .left .title {
  font-size: 28px;
  color: var(--gray-01);
  font-weight: 400;
}

.common-header-container .left .title.strong {
  font-size: 28px;
  color: white;
  font-weight: 400;
}

.common-header-container .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.common-header-container .right .user-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  border: 1px solid var(--gray-01);
  border-radius: 50%;
  width: 46px;
  height: 46px;
}


.common-header-container .right .user-info-container .user-name {
  font-size: 16px;
  text-decoration: underline;
  text-underline-offset: 5px;
  cursor: pointer;
}

.common-header-container .right .time {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.common-header-container .right .time .current-time-container {
  display: flex;
  align-items: baseline;
}

.common-header-container .right .time .current-time-container .current-time {
  font-size: 24px;
  font-weight: bold;
}

.common-header-container .right .time .current-time-container .meridiem {
  font-size: 16px;
  font-weight: bold;
}

.common-header-container .right .time .today-date {
  font-size: 14px;
  margin-left: 10px;
}
