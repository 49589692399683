/* user Management Section */
.user-container {
    width: 100%;
    height: 100%;
    padding: 50px;
}

.user-container .content {
    display: flex;
    width: 100%;
    height: 100%;
}

.user-container .content .search-title {
    font-size: 14px;
    color: #8088A0;
}

.user-container .content .left {
    width: 20%
}

.user-container .content .right {
    width: 100%;
    height: 100%;
}

.user-container .content .right .header {
    color: white;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 50px;
    line-height: 32px;
}


.user-container .search-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 40px;
    width: 100%;
    justify-content: space-between;
}

.user-container .btn-regi {
    background-color: gray;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    color: #496ded;
    font-size: 16px;
    font-weight: bold;
}

.user-container .search-container .select {
    font-size: 14px;
    color: white;
    border-radius: 5px;
    background-color: var(--gray-04);
    width: 120px;
    height: 100%;
    margin-right: 10px;
    border: none;
    padding: 0 10px;
}

.user-container .search-container .select.department {
    width: 160px;
}

.user-container .search-container .select.event-access {
    width: 260px;
}

.user-container .search-container .select::placeholder {
    color: white;
    font-size: 14px;
}

.user-container .search-container .search {
    padding: 0px 10px;
    border-radius: 5px;
    color: white;
    background-color: var(--gray-04);
    width: 300px;
    height: 100%;
}

.user-container .search-container .search::placeholder {
    color: #8088a0;
}

.user-container .btn-change-auth {
    margin-left: 12px;
}


.user-container .btn-bottom {
    width: 110px;
    height: 40px;
    border: 1px solid var(--gray-00);
    border-radius: 5px;
    background-color: var(--main-ny-black);
    color: var(--gray-00);
    font-size: 16px;
    font-weight: bold;
}

