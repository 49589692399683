.input-component:focus {
    border: 1px solid #4382f6 !important;
}

.textarea-component:focus {
    border: 1px solid #4382f6 !important;
}

.input-component::placeholder {
    color: lightgray;
}
