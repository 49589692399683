.change-pwd-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #1B2649;
    padding: 50px;
    border-radius: 5px;
}

.change-pwd-container .main-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.change-pwd-container .sub-title {
    margin-bottom: 30px;
    color: #999999;
}

.change-pwd-container .input-container {
    margin-bottom: 20px;
}

.change-pwd-container .input-container .title {
    margin-bottom: 5px;
    font-weight: bold;
    color: #6b6b6b;
    font-size: 14px;
}

.change-pwd-container .input-container .input {
    border: 1px solid #999999;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

.change-pwd-container .btn-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.change-pwd-container .btn-container .btn {
    width: 48%;
    border-radius: 5px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
}

.change-pwd-container .btn-container .btn.back {
    background-color: white;
    border: 1px solid var(--main-ny-black);
    color: #1B2649;
}
