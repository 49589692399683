/* Regi Facility Complete Modal Section */
.complete-and-close-modal-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  justify-content: center;
  height: 100%;
}

.complete-and-close-modal-container .header {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.complete-and-close-modal-container .confirm {
  font-size: 17px;
  margin-bottom: 20px;
  line-height: 25px;
  text-align: center;
  white-space: pre-line;
}

.complete-and-close-modal-container .btn-container {
  display: flex;
  justify-content: space-between;
}

.complete-and-close-modal-container .btn-container > button {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.complete-and-close-modal-container .btn-container .btn-close {
  background-color: var(--main-ny-sub-black);
  color: white;
}
