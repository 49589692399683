.group-header-container {
  display: flex;
  align-items: center;
}

.group-header-container .btn {
  color: white;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 30px;
  cursor: pointer;
}

.group-header-container .btn.btn-user {
  margin-right: 20px;
}
