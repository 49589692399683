.cctv-item-container {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    border: 1px solid #656565;
}
.cctv-item-container canvas {
    border-radius: 5px;
}
.cctv-item-container .cctv-overlay {
    width: 100%;
    z-index: 1;
    font-size: 11px;
    color: #FFFFFF;
    position: absolute;
    bottom: 0;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cctv-item-container .cctv-name {
    border: 1px solid #FFFFFF;
    padding: 2px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cctv-item-container .disabled-cctv-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 4px;
    color: #AAAAAA;
    background-color: #FFFFFF30;
    flex-direction: column;
    position: absolute;
    background-color: #FFFFFF30;

}
.cctv-item-container .disabled-cctv-overlay .not-active-text {
    margin-top: 7px;
    font-size: 12px;
    text-align: center;
    line-height: 1.2;
}

.cctv-item-container .retry-text {
    display: flex;
    align-items: center;
}
.cctv-item-container .retry-text .retry-icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
}
