.timeout-screen-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
}

.timeout-screen-container .header {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 30px;
}

.timeout-screen-container .left-time {
    color: #FF0000;
    font-weight: bold;
    margin-left: 5px;
}
