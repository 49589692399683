.button {
    width: 110px;
    height: 40px;
    border: 1px solid var(--gray-00);
    border-radius: 5px;
    background-color: var(--main-ny-black);
    color: var(--gray-00);
    font-size: 16px;
    font-weight: bold;
    transition: transform 0.2s ease-in-out;
}

.button:hover {
    color: red;
    border-color: red;
}
