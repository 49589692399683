.event-type {
    width: 45px;
    height: 45px;
}

.event-container {
    width: 100%;
    height: 100%;
    padding: 50px;
}

.event-container .content {
    display: flex;
    width: 100%;
    height: 100%;
}

.event-container .content .left {
    width: 20%
}

.event-container .content .right {
    width: 100%;
}

.event-container .content .right .header {
    color: white;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 50px;
    line-height: 32px;
}

.event-container .search-title {
    font-size: 14px;
    color: #8088a0;
}

.event-container .function-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.event-container .function-container .left-side {
    display: flex;
    align-items: center;
}

.event-container .function-container .date-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.event-container .function-container .date-container .date-picker {
    width: 170px;
    height: 40px;
    background-color: var(--gray-04);
    border: none;
    border-radius: 5px;
    color: white;
    padding: 0px 10px;
    font-size: 14px;
}

.event-container .function-container .react-datepicker__close-icon::after {
    background-color: transparent;
}

.event-container .function-container .date-container .wave {
    margin: 0px 5px;
    color: #8088A0;
}

.event-container .function-container .date-container .btn-inquery {
    width: 80px;
    height: 37px;
    border-radius: 5px;
    background-color: #6c6c6c;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
}

.event-container .function-container .btn-send {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    color: var(--main-ny-blue);
    display: flex;
    align-items: center;
    justify-content: center;
}

.event-container .search-container {
    display: flex;
    justify-content: right;
}

.event-container .tableBody:hover {
    background-color: #e4e4324b;
}

.event-container .search-container .select {
    font-size: 14px;
    color: white;
    border-radius: 5px;
    background-color: var(--gray-04);
    width: 120px;
    height: 40px;
    margin-right: 10px;
    border: none;
    padding: 0 10px;
}

.event-container .search-container .search {
    /* margin: 10px 0px; */
    width: 300px;
    height: 38px;
    padding: 0 10px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: var(--gray-04);
}

.event-container .search-container .search::placeholder {
    color: #8088a0;
}

.event-container .btn-container {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
}

.event-container .btn-container .MuiPaginationItem-text {
    color: var(--gray-00);
}

.event-container .btn-container .MuiPaginationItem-text.Mui-selected {
    color: white;
}

.event-container .btn-del {
    width: 110px;
    height: 40px;
    border: 1px solid var(--gray-00);
    border-radius: 5px;
    background-color: var(--main-ny-black);
    color: var(--gray-00);
    font-size: 16px;
    font-weight: bold;
}

.strong {
    font-weight: bold;
}
