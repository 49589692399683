.reset-pwd-modal-container {

}

.reset-pwd-modal-container .input-container {
    margin-bottom: 20px;
}

.reset-pwd-modal-container .input-container .title {
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
}

.reset-pwd-modal-container .input-container .input {
    border: 1px solid #999999;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}
