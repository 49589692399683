.list-container {
  width: 270px;
  min-height: 80px;
  height: auto;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 14px;
}

.list-container .title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.list-container .detail {
  font-size: 14px;
  line-break: anywhere;
}

