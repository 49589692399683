.statistics-container .progressChart ::-webkit-calendar-picker-indicator,
.statistics-container .distribution-chart ::-webkit-calendar-picker-indicator {
    filter: invert(100%) sepia(100%) saturate(100%) ;
}
.distribution-chart foreignObject {
    overflow: visible;
}
.distribution-chart .apexcharts-radialbar {
    transform: rotate(180deg);
    transform-origin: 30% 47%;
}
.disaster-container .indicator.run {
    /*animation: rotate 1s infinite ;*/
    animation:spin 1s linear infinite;
}
.distribution-chart .MuiInputBase-input,
.statistics-container .progressChart .MuiInputBase-input {
    padding: 8px 14px;
}
.distribution-chart .MuiInputAdornment-root svg,
.statistics-container .progressChart .MuiInputAdornment-root svg{
    color: #FFF;
}



@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(180deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(180deg);
    }
}
